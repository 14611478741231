export default {
    individual: "Individual",
    individualHelp: "Export only the front image of the product",
    zoom: "Zoom",
    zoomHelp: "Exports only a zoom of the product material",
    thelook: "Catalog Images",
    thelookHelp: "Export only the product catalog images",
    all: "All",
    allHelp: "Export all images of the product",
    videos: "Videos",
    videosHelp: "Export all videos of the products",
    helpDownload: "We are generating your file, you can wait here for a few minutes or we will send it to you by mail",
    alertReferences: "There is no valid reference for export",
    alertVideos: "The references sent do not have videos",
    alertZoom: "The zoom option only allows up to 25 references",
};
