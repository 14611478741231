import React from "react";
import styled from "styled-components";
import Datasheet from "react-datasheet";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
// import Button from "../../components/Button";
import JSZipUtils from "../../../lib/jzip-utils.js";
import "react-datasheet/lib/react-datasheet.css";

import { Button, Buttons } from "../../../sdk/Button";
import { Control, Field, FieldBody, Radio } from "../../../sdk/Form";
import {
    Columns,
    Column,
    Section,
    Subtitle,
    Box,
    Container,
} from "../../../sdk/Layout";
import Icons from "../../../components/Icons2";

import Modal from "../../../components/Modal";

import lang_es from "./lang/lang_es";
import lang_en from "./lang/lang_en";
import lang_pt from "./lang/lang_pt";
import { Message } from "../../../sdk/Elements.js";
import Blueberry from "../../../../blueberry/index.js";

const SectionExtended = styled(Section)`
    background-color: ${(props) => props.theme.grayLight};
`;

const FluidBox = styled(Box)`
    @media (max-width: 500px) {
        padding: 0;
    }
`;

const ErrorBlock = styled.div`
    margin-top: 10px;
`;

function getLang(lang) {
    switch (lang) {
        case "es":
            return lang_es;
        case "en":
            return lang_en;
        case "pt":
            return lang_pt;
    }
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

function StatusImage({ loading, error }) {
    return (
        <>
            {!loading && !error ? (
                <StatusIcon
                    color="success"
                    title="Product encontrado, imagen existe"
                >
                    <Icons icon="check-alt"></Icons>
                </StatusIcon>
            ) : !loading && error ? (
                <StatusIcon color="error" title="Product no encontrado">
                    <Icons icon="close"></Icons>
                </StatusIcon>
            ) : null}
            {loading ? <ButtonExtended loading></ButtonExtended> : null}
        </>
    );
}

function StatusVideo({ loading, error }) {
    if (loading) return <ButtonExtended loading></ButtonExtended>

    if (!error) return (
        <StatusIcon
            color="success"
            title="Video encontrado"
        >
            <Icons icon="check-alt"></Icons>
        </StatusIcon>
    )
    
    if (error) return (
        <StatusIcon color="error" title="Video no encontrado">
            <Icons icon="close"></Icons>
        </StatusIcon>            
    )

    return null;
}

const ImageExportWrapper = styled.div`
    padding: 50px 30px;
    @media (max-width: 768px) {
        padding: 20px 10px;
    }
    .export-image-container {
        display: block;
        margin: auto;
        max-width: 263px;
        button {
            display: block;
            margin: 20px auto 10px;
        }
        .red-text {
            color: @red;
            margin-bottom: 25px;
            text-align: center;
            font-weight: bold;
        }
        .blue-text {
            color: @blue;
            text-align: center;
            font-weight: bold;
            margin-bottom: 25px;
        }
    }
    .new-notification {
        color: @red;
        font-size: 11px;
        margin-left: 10px;
    }

    .image-export-settings {
        &--multiplier {
            text-align: center;
            margin-top: 5px;
        }
        &--toggle {
            cursor: pointer;
        }
        &--size {
            margin: 20px 0;
        }
        &--hidden {
            height: 0;
            overflow: hidden;
            display: block;
            &.visible {
                height: auto;
            }
        }
    }
    .data-grid-container {
        display: block;
        width: 100%;
        table {
            width: 100%;
            tr {
                td:first-child {
                    width: 50px;
                }
                td,
                th {
                    vertical-align: middle !important;
                    padding: 2px !important;
                }
                td:not(.read-only) {
                    text-align: left !important;
                }
            }
            .data-editor {
                height: inherit !important;
                font-size: 16px !important;
                border: none !important;
                text-align: left !important;
            }
        }
    }
`;

const Header = styled(Columns)`
    p {
        font-size: 14px;
        color: ${(props) => props.theme.primaryDark};
    }
    margin-bottom: 20px !important;
`;

const Settings = styled(Columns)`
    ${Column} {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    p {
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: ${(props) => props.theme.gray};
        b {
            font-weight: bold;
        }
    }
`;

const RadioExtended = styled(Radio)`
    display: block;
    label {
        background-color: ${(props) => props.theme.white};
        justify-content: start;
    }
    p {
        padding-left: 10px;
    }
`;

const Block = styled.div`
    & + & {
        margin-top: 30px;
    }
`;

const color = {
    success: (props) => props.theme.success,
    error: (props) => props.theme.error,
};

const StatusIcon = styled.i`
    display: inline-flex;
    justify-content: center;
    align-content: center;
    svg {
        width: 20px;
        height: 20px;
        color: ${(props) => color[props.color]};
    }
`;

const MiniThumb = styled.figure`
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 2px;
    display: inline-block;
    &:before {
        content: "";
        display: block;
        padding-top: 60%;
    }
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }
`;

const ButtonExtended = styled(Button)`
    border: none;
    width: 20px;
    height: 20px;
    padding: 0;
    background-color: transparent;
    display: inline-flex;
    &:after {
        left: 0;
        top: 0;
    }
`;

const iconField = {
    readOnly: true,
    width: 36,
    forceComponent: true,
};

class ImageExport extends React.PureComponent {
    constructor(props) {
        super(props);

        this.jsonLang = getLang(props.lang);

        let transfers = [];
        if (
            props.location.search &&
            props.location.search.indexOf("imageTransfer=true") > -1
        ) {
            let slugs = localStorage.getItem("items-transfer");

            transfers = slugs ? JSON.parse(slugs) : [];
        }

        const count = transfers.length > 100 ? transfers.length : 100;

        const rows = [];
        for (var i = 0; i <= count; i++) {
            rows.push([
                { readOnly: true, value: i + 1 },
                { value: transfers && transfers[i] ? transfers[i] : "" },
                { value: "" },
                { value: "", ...iconField },
                { value: "", ...iconField },
            ]);
        }
        this.state = {
            grid: [
                [
                    { readOnly: true, value: "" },
                    { value: "Referencias", readOnly: true },
                    { value: "Nombre para Export (Opcional)", readOnly: true },
                    { value: "", readOnly: true, width: 36, forceComponent: true, component: <Icons icon="images-solid" size={20} /> },
                    { value: "", readOnly: true, width: 36, forceComponent: true, component: <Icons icon="video-solid" size={20} /> },
                ],
                ...rows,
            ],
            downloading: false,
            errors: [],
            error: "",
            count: 0,
            current: 0,
            maxWidth: 2000,
            isAdvanceSettingsVisible: false,
            maxHeight: 2000,
            isModalVisible: false,
            typeOfExport: "individual",
        };
    }

    errors = [];
    timeouts = {};

    componentDidMount = () => {
        if (
            this.props.location.search &&
            this.props.location.search.indexOf("imageTransfer=true") > -1
        ) this.validateImageInDb();
    }

    setValue = (e) => {
        const obj = {};
        obj[e.target.name] =
            e.target.name === "maxWidth" || e.target.name === "maxHeight"
                ? parseInt(e.target.value)
                : e.target.value;
        this.setState(obj);
    };

    toggleLoader = () => {
        this.setState((prevState) => {
            return {
                downloading: !prevState.downloading,
            };
        });
    };

    toggleModalLoader = () => {
        this.setState((prevState) => {
            return {
                isModalVisible: !prevState.isModalVisible,
            };
        });
    };

    closeModal = () => {
        this.setState({ isModalVisible: false });
    };

    toggleAdvancedSettings = () => {
        this.setState((prevState) => {
            return {
                isAdvanceSettingsVisible: !prevState.isAdvanceSettingsVisible,
            };
        });
    };

    downloadZoom = () => {
        const { user } = this.props;
        const { grid, maxWidth, maxHeight } = this.state;
        const instance = this;
        this.toggleModalLoader();

        if (typeof window !== "undefined" && analytics)
            analytics.track("Download Zoom Images My Account", {
                maxWidth,
                maxHeight,
            });

        const email = user.emails[0].address;
        const imageList = [];

        for (let i = 1; i < grid.length; i++) {
            if (grid[i][1] && grid[i][1] && grid[i][1].value !== "") {
                let realFilename = grid[i][1].value
                    .trim()
                    .toLowerCase()
                    .replace("/", "-sz");
                let alertnativeFilename =
                    grid[i][2].value.trim() !== "" &&
                    grid[i][2].value.trim() != null
                        ? grid[i][2].value.trim()
                        : realFilename;
                imageList.push([`${realFilename}`, `${alertnativeFilename}`]);
            }
        }

        axios({
            method: "PUT",
            timeout: 10000000,
            headers: {
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin": "*"
                // "InvocationType": "Event",
            },
            data: JSON.stringify({
                images: imageList,
                email,
                event: "zoom",
                target_width: maxWidth,
                target_height: maxHeight,
            }),
            url: "https://api.noritex.com/exports/images",
        })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {});
    };

    downloadMediaEmail = () => {
        const { user } = this.props;
        const { grid, maxWidth, maxHeight } = this.state;
        const instance = this;
        this.toggleModalLoader();

        const email = user.emails[0].address;
        const imageList = [];

        for (let i = 1; i < grid.length; i++) {
            if (grid[i][1] && grid[i][1] && grid[i][1].value !== "") {
                let realFilename = grid[i][1].value
                    .trim()
                    .toLowerCase()
                    .replace("/", "-sz");
                let alertnativeFilename =
                    grid[i][2].value.trim() !== "" &&
                    grid[i][2].value.trim() != null
                        ? grid[i][2].value.trim()
                        : realFilename;
                imageList.push([`${realFilename}`, `${alertnativeFilename}`]);
            }
        }

        axios({
            method: "POST",
            timeout: 10000000,
            headers: {
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin": "*"
            },
            data: JSON.stringify({
                images: imageList,
                email,
                event: "resize",
                targe_size: maxWidth,
                target_size: maxWidth,
            }),
            url: "https://api.noritex.com/exports/images",
        })
            .then((response) => {
                this.setState({
                    startFetch: true,
                    downloadLink: response.data.downloadLink,
                });
                this.startInterval();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    isValidExport = (typeOfExport, grid) => {
        let rows = [];
        for (let i = 1; i < grid.length; i++) {
            const row = grid[i];
            const checkCol = row[3].component;
            const videoCol = row[4].component;
            let rowCheck = {};

            if (checkCol) rowCheck["isError"] = checkCol.props.error;
            if (videoCol) rowCheck["hasVideos"] = !videoCol.props.error;
            if (Object.keys(rowCheck).length > 0) rows.push(rowCheck);
        }

        const success_rows = rows.filter(row => !row.isError);

        // si todas las filas tienen errores, es invalido
        if (rows.every(row => row.isError)) {
            this.setState({...this.state, error: this.jsonLang.alertReferences});
            return false;
        }

        // en caso de videos que tenga al menos alguna video, sino invalido
        if (typeOfExport === "videos" && !rows.some(row => row.hasVideos)) {
            this.setState({...this.state, error: this.jsonLang.alertVideos});
            return false;
        }

        // en caso de zoom solo exportar hasta 25, sino invalido
        if (typeOfExport === "zoom" && success_rows.length > 25) {
            this.setState({...this.state, error: this.jsonLang.alertZoom});
            return false;
        }

        return true;
    }

    downloadAmbientadas = (typeOfExport) => {
        const { user } = this.props;
        const { grid, maxWidth, maxHeight } = this.state;
        //const instance = this;

        // Verificar si hay media disponibles antes de llamar al api
        if (!this.isValidExport(typeOfExport, grid)) {
            return;
        }

        this.toggleModalLoader();
        this.setState({ downloadLink: null, foundFile: null });

        const email = user.emails[0].address;
        const imageList = [];

        for (let i = 1; i < grid.length; i++) {
            if (grid[i][1] && grid[i][1] && grid[i][1].value !== "") {
                let realFilename = replaceAll(
                    grid[i][1].value.trim().toLowerCase().replace("/", "-sz"),
                    "/",
                    "-sz"
                );
                let alertnativeFilename =
                    grid[i][2].value.trim() !== "" &&
                    grid[i][2].value.trim() != null
                        ? grid[i][2].value.trim()
                        : realFilename;
                imageList.push([`${realFilename}`, `${alertnativeFilename}`]);
            }
        }

        axios({
            method: "POST",
            timeout: 10000000,
            headers: {
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin": "*"
            },
            data: JSON.stringify({
                typeOfExport,
                images: imageList,
                email,
                event: "all",
                targe_size: maxWidth,
                target_size: maxWidth,
            }),
            url:
                // "https://api.noritex.com/exports/images",
                "https://api.noritex.com/exports/images",
        })
            .then((response) => {
                this.setState({
                    startFetch: true,
                    downloadLink: response.data.downloadLink,
                });
                this.startInterval();
            })
            .catch((err) => {});
    };

    contador = 0;
    idVar = 0;

    testFileExists(src, successFunc, failFunc) {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === this.DONE) {
                if (xhr.status === 403 || xhr.status === 404) {
                    failFunc(xhr);
                } else {
                    successFunc(xhr);
                }
            }
        };
        // xhr.error = function() {
        //     failFunc(xhr);
        // }
        // xhr.onabort = function() {
        //     failFunc(xhr);
        // }
        // xhr.timeout = function() {
        //     failFunc(xhr);
        // }
        xhr.timeout = 3000; // TIMEOUT SET TO PREFERENCE (3 SEC)
        xhr.open("HEAD", src, true);
        xhr.send(null); // VERY IMPORTANT
    }

    forceDownload(href) {
        var anchor = document.createElement("a");
        anchor.href = href;
        anchor.download = href;
        document.body.appendChild(anchor);
        anchor.click();
    }

    fileExists = (xhr) => {
        //alert("File exists !!  Yay !!");
        this.setState({ foundFile: true });
        clearInterval(this.idVar);
        this.forceDownload(this.state.downloadLink);
    };

    fileNotFound = (xhr) => {
        //alert("Cannot find the file, "+contador+' '+xhr.status);

        console.log("File not found: Try " + this.contador);
        this.contador = this.contador + 1;
    };

    startInterval = () => {
        const testFileExists = this.testFileExists;
        console.log(this.testFileExists);
        this.idVar = setInterval(() => {
            console.log("run interval");
            testFileExists(
                this.state.downloadLink,
                this.fileExists,
                this.fileNotFound
            );
        }, 3000);
    };

    toggleDownloader = () => {
        const { maxWidth, maxHeight } = this.state;
        if (typeof window !== "undefined" && analytics)
            analytics.track("Download Images My Account", {
                maxWidth,
                maxHeight,
            });

        // if (maxWidth !== 2000 || maxHeight !== 2000) {
        if (true) {
            this.downloadMediaEmail();
        } else {
            this.downloadMedia();
        }
    };

    downloadMedia = (key) => {
        const { grid } = this.state;
        const instance = this;

        const imageList = [];

        this.setState({ count: imageList.length });

        for (let i = 1; i < grid.length; i++) {
            if (grid[i][1] && grid[i][1] && grid[i][1].value !== "") {
                let realFilename = grid[i][1].value
                    .trim()
                    .toLowerCase()
                    .replace("/", "-sz");
                let alertnativeFilename =
                    grid[i][2].value.trim() !== "" &&
                    grid[i][2].value.trim() != null
                        ? grid[i][2].value.trim()
                        : realFilename;
                imageList.push({
                    img: `https://ntx-images.s3.amazonaws.com/${realFilename}.jpg`,
                    filename: `${alertnativeFilename}.jpg`,
                });
            }
        }

        if (imageList.length > 0) {
            this.toggleLoader();
        }

        this.setState({ count: imageList.length });

        var zip = new JSZip();
        this.download_images(zip, imageList, 0, (content) => {
            saveAs(content, "archive.zip");
            const errors = instance.errors;
            instance.errors = [];
            instance.setState({
                downloading: false,
                errors,
                count: 0,
                current: 0,
            });
            console.log("Done.");
        });
    };

    download_images = (zip, files, index, complete) => {
        index = index || 0;
        const curFile = files[index];
        const instance = this;
        JSZipUtils.getBinaryContent(curFile.img, (err, data) => {
            if (err) {
                instance.errors.push(curFile.img);
            } else {
                const filename = curFile.filename;
                zip.file(filename, data, { binary: true });
            }

            index++;

            if (index < files.length) {
                this.setState({ current: index + 1 });
                instance.download_images(zip, files, index, complete);
            } else {
                zip.generateAsync({ type: "blob" }).then((content) => {
                    complete(content);
                });
            }
        });
    };

    iniateValidation = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.validateImageInDb();
        }, 1000);
    };

    skuToSlug = (slug) => {
        return replaceAll(
            slug.trim().toLowerCase().replace("/", "-sz"),
            "/",
            "-sz"
        );
    };

    validateImageInDb = () => {
        const { grid } = this.state;
        const slugs = [];

        for (let i = 1; i < grid.length; i++) {
            if (grid[i][1] && grid[i][1] && grid[i][1].value !== "") {
                slugs.push(this.skuToSlug(grid[i][1].value));
            }
        }

        Blueberry.productsValidate({ slugs })
            .then((resp) => {
                if (resp) {
                    const toUpdategrid = grid.map((index, i) => {
                        const newRow = [...index];

                        const slug =
                            index[1] && index[1].value
                                ? this.skuToSlug(index[1].value)
                                : null;

                        if (i > 0) {
                            newRow[3] = {
                                readOnly: true,
                                width: 36,
                                forceComponent: true,
                                component:
                                    index[1] &&
                                    index[1].value &&
                                    index[1].value !== "" ? (
                                        <StatusImage
                                            loading={false}
                                            error={
                                                slug && resp[slug]
                                                    ? !resp[slug].valid
                                                    : true
                                            }
                                        />
                                    ) : null,
                            };
                            // videos?
                            newRow[4] = {
                                readOnly: true,
                                width: 36,
                                forceComponent: true,
                                component:
                                    index[1] &&
                                    index[1].value &&
                                    index[1].value !== "" ? (
                                        <StatusVideo
                                            loading={false}
                                            error={!resp[slug].hasVideos}
                                        />
                                    ) : null,
                            };
                        }

                        return newRow;
                    });
                    this.setState(prevState => { return {...prevState, grid: toUpdategrid} });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleDownload = (e) => {
        const { typeOfExport } = this.state;
        switch (typeOfExport) {
            case "all":
                this.downloadAmbientadas("all");
                break;
            case "thelook":
                this.downloadAmbientadas("lookOnly");
                break;
            case "zoom":
                this.downloadAmbientadas("zoom");
                break;
            case "individual":
                this.downloadAmbientadas("frontal");
                break;
            case "videos":
                this.downloadAmbientadas("videos");
                break;
        }
    };

    render() {
        const {
            typeOfExport,
            downloading,
            errors,
            count,
            current,
            maxHeight,
            maxWidth,
            isAdvanceSettingsVisible,
            isModalVisible,
            foundFile,
            downloadLink,
        } = this.state;
        const { lang } = this.props;

        const jsonLang = getLang(lang);

        return (
            <SectionExtended>
                <Container>
                    <ImageExportWrapper>
                        <Header>
                            <Column>
                                <p>1. {this.props._t("downloadImageHelp1")}</p>
                            </Column>
                            <Column>
                                {/* <p>2. {this.props._t("downloadImageHelp2")}</p> */}
                                <p>2. {this.props._t("downloadImageHelp2")}</p>
                            </Column>
                        </Header>
                        <Columns>
                            <Column>
                                <FluidBox>
                                    <div className="sheet-container">
                                        <Datasheet
                                            data={this.state.grid}
                                            valueRenderer={(cell) => cell.value}
                                            onContextMenu={(e, cell, i, j) =>
                                                cell.readOnly
                                                    ? e.preventDefault()
                                                    : null
                                            }
                                            onCellsChanged={(
                                                changes,
                                                additions
                                            ) => {
                                                const grid =
                                                    this.state.grid.map(
                                                        (row) => [...row]
                                                    );
                                                changes.forEach(
                                                    ({
                                                        cell,
                                                        row,
                                                        col,
                                                        value,
                                                    }) => {
                                                        if (!grid[row]) {
                                                            grid[row] = [
                                                                {
                                                                    readOnly: true,
                                                                    value:
                                                                        row + 1,
                                                                },
                                                                {
                                                                    value: value,
                                                                },
                                                                { value: "" },
                                                                {
                                                                    ...iconField,
                                                                    component: (
                                                                        <StatusImage
                                                                            loading={
                                                                                true
                                                                            }
                                                                        ></StatusImage>
                                                                    ),
                                                                },
                                                            ];
                                                        } else {
                                                            grid[row][col] = {
                                                                ...grid[row][
                                                                    col
                                                                ],
                                                                value,
                                                            };
                                                            grid[row][3] = {
                                                                ...iconField,
                                                                component: (
                                                                    <StatusImage
                                                                        loading={
                                                                            true
                                                                        }
                                                                    ></StatusImage>
                                                                ),
                                                            };
                                                        }
                                                    }
                                                );

                                                if (additions) {
                                                    additions.forEach(
                                                        ({
                                                            cell,
                                                            row,
                                                            col,
                                                            value,
                                                        }) => {
                                                            if (!grid[row]) {
                                                                grid[row] = [
                                                                    {
                                                                        readOnly: true,
                                                                        value:
                                                                            row +
                                                                            1,
                                                                    },
                                                                    {
                                                                        value: value,
                                                                    },
                                                                    {
                                                                        value: "",
                                                                    },
                                                                ];
                                                            } else {
                                                                grid[row][col] =
                                                                    {
                                                                        ...grid[
                                                                            row
                                                                        ][col],
                                                                        value,
                                                                    };
                                                            }
                                                        }
                                                    );
                                                }
                                                this.setState({ grid });
                                                this.iniateValidation();
                                            }}
                                        />
                                    </div>
                                </FluidBox>
                            </Column>
                            <Column>
                                <Modal
                                    visible={isModalVisible}
                                    close={this.closeModal}
                                    modalHeader={
                                        <h4>
                                            {this.props._t("processingRequest")}
                                        </h4>
                                    }
                                >
                                    <p>{jsonLang.helpDownload}</p>

                                    <Buttons isCentered>
                                        <a href={downloadLink}>
                                            <Button
                                                primary
                                                loading={
                                                    foundFile ? undefined : true
                                                }
                                            >
                                                Download File
                                            </Button>
                                        </a>
                                    </Buttons>
                                </Modal>
                                <Block>
                                    <Settings>
                                        <Column>
                                            <Field>
                                                <RadioExtended>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="typeOfExport"
                                                            value="individual"
                                                            onChange={
                                                                this.setValue
                                                            }
                                                            checked={
                                                                typeOfExport ===
                                                                "individual"
                                                            }
                                                        />
                                                        <span></span>
                                                        <p>
                                                            {
                                                                jsonLang.individual
                                                            }
                                                        </p>
                                                    </label>
                                                </RadioExtended>
                                            </Field>
                                            <Field>
                                                <RadioExtended>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="typeOfExport"
                                                            value="zoom"
                                                            onChange={
                                                                this.setValue
                                                            }
                                                            checked={
                                                                typeOfExport ===
                                                                "zoom"
                                                            }
                                                        />
                                                        <span></span>
                                                        <p>{jsonLang.zoom}</p>
                                                    </label>
                                                </RadioExtended>
                                            </Field>
                                            <Field>
                                                <RadioExtended>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="typeOfExport"
                                                            value="thelook"
                                                            onChange={
                                                                this.setValue
                                                            }
                                                            checked={
                                                                typeOfExport ===
                                                                "thelook"
                                                            }
                                                        />
                                                        <span></span>
                                                        <p>
                                                            {jsonLang.thelook}
                                                        </p>
                                                    </label>
                                                </RadioExtended>
                                            </Field>
                                            <Field>
                                                <RadioExtended>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="typeOfExport"
                                                            value="all"
                                                            onChange={
                                                                this.setValue
                                                            }
                                                            checked={
                                                                typeOfExport ===
                                                                "all"
                                                            }
                                                        />
                                                        <span></span>
                                                        <p>{jsonLang.all}</p>
                                                    </label>
                                                </RadioExtended>
                                            </Field>
                                            <Field>
                                                <RadioExtended>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="typeOfExport"
                                                            value="videos"
                                                            onChange={
                                                                this.setValue
                                                            }
                                                            checked={
                                                                typeOfExport ===
                                                                "videos"
                                                            }
                                                        />
                                                        <span></span>
                                                        <p>{jsonLang.videos}</p>
                                                    </label>
                                                </RadioExtended>
                                            </Field>
                                        </Column>
                                        <Column>
                                            <p>
                                                <b>{jsonLang[typeOfExport]}.</b>{" "}
                                                {
                                                    jsonLang[
                                                        typeOfExport + "Help"
                                                    ]
                                                }
                                            </p>
                                        </Column>
                                    </Settings>
                                </Block>
                                <Block>
                                    <Field isHorizontal>
                                        <FieldBody isFlexEnd>
                                            <Field>
                                                <label>
                                                    {this.props._t(
                                                        "showAdvancedSettings"
                                                    )}
                                                </label>
                                                <Field hasAddons>
                                                    <Control>
                                                        <Button static>
                                                            Width
                                                        </Button>
                                                    </Control>
                                                    <Control>
                                                        <input
                                                            type="number"
                                                            value={maxWidth}
                                                            onChange={
                                                                this.setValue
                                                            }
                                                            name="maxWidth"
                                                        />
                                                    </Control>
                                                </Field>
                                            </Field>
                                            <Field>
                                                <Field hasAddons>
                                                    <Control>
                                                        <Button static>
                                                            Height
                                                        </Button>
                                                    </Control>
                                                    <Control>
                                                        <input
                                                            type="number"
                                                            value={maxWidth}
                                                            onChange={
                                                                this.setValue
                                                            }
                                                            name="maxWidth"
                                                        />
                                                    </Control>
                                                </Field>
                                            </Field>
                                        </FieldBody>
                                    </Field>
                                </Block>
                                <Block>
                                    {errors.map((index, i) => {
                                        return (
                                            <Message
                                                error
                                                className="red-text"
                                                key={"media-key" + i}
                                            >
                                                <strong>Error With:</strong>:{" "}
                                                {index}
                                            </Message>
                                        );
                                    })}
                                    <Button
                                        isFullWidth
                                        primary
                                        onClick={this.handleDownload}
                                        loading={
                                            downloading
                                                ? downloading
                                                : undefined
                                        }
                                    >
                                        <Icons icon="download"></Icons>
                                        <span>
                                            {this.props._t("downloadImages")}
                                        </span>
                                    </Button>
                                    { (this.state.error) &&
                                        <ErrorBlock>
                                            <Message
                                                error
                                                className="red-text"
                                            >
                                                {this.state.error}
                                            </Message>
                                        </ErrorBlock>
                                    }
                                </Block>
                            </Column>
                        </Columns>
                    </ImageExportWrapper>
                </Container>
            </SectionExtended>
        );
    }
}

export default ImageExport;
