export default {
    individual: "Individual",
    individualHelp: "Exporta solo la imagen frontal del producto",
    zoom: "Zoom",
    zoomHelp: "Exporta solo un zoom del material del producto",
    thelook: "Ambientada",
    thelookHelp: "Exporta solo la ambientada del producto",
    all: "Todas",
    allHelp: "Exporta todas las imágenes del producto",
    videos: "Videos",
    videosHelp: "Exporta todos los videos del producto",
    helpDownload: "Estamos generando tu archivo puedes esperar aqui unos minutos o te lo enviaremos por correo",
    alertReferences: "No hay ninguna referencia válida para exportar",
    alertVideos: "Las referencias enviadas no cuentan con videos",
    alertZoom: "La opción de zoom solo permite hasta 25 referencias",
};
